function toggleMobileMenu() {

    var overlay     = '.overlay';
    var header      = 'header';
    var body        = 'body';

    jQuery(overlay).fadeToggle(200);
    jQuery(header).toggleClass('open');
    jQuery(body).toggleClass('mobile_menu_open');
}
/**
 * Toggle event
 */
jQuery(document).ready(function($) {

    var $button     = $('#mobile-menu-button');
    $button.click(function(){
        toggleMobileMenu();
    });

    // $(document).keyup(function(e) {
    //     if (e.keyCode === 27) toggleMobileMenu();   // esc || space
    // });


});
/**
 * Sub menu show/hide
 */
jQuery(document).ready(function($) {

    var $subMenuLinks = $('.overlay .mobilmenu ul.mobile_menu > li.menu-item.menu-item-has-children > a');

    $subMenuLinks.click(function(event) {
        event.preventDefault();
        console.log();
        $(this).parent().find('ul.sub-menu').toggleClass('open');
        $(this).parent().toggleClass('open');
    });

});
