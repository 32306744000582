// as the page loads, call these scripts
jQuery(document).ready(function($) {

    checkScroll();
    $(window).scroll(checkScroll);

    $('header .header_title').addClass('show');

    // make all pdf links open in new window.
    $('a[href$=".pdf"]').attr('target', '_blank');

    //getting viewport width
    var responsive_viewport = $(window).width();

    // if is below 481px
    if (responsive_viewport < 481) {

    }

    //if is larger than 481px
    if (responsive_viewport > 481) {

        var $answers = $('.faq .answers');
        var $questions = $('.faq .questions');
        var $infobox = $('.faq .answers .info');

        $answers.on('show.bs.collapse','.answer.collapse', function() {

            var visibleAnswers = '.answer.collapse.show';
            $answers.find(visibleAnswers).collapse('hide');
            $infobox.addClass('d-none');
            var elmnt = document.querySelector('.faq')
            //elmnt.scrollIntoView(true);

        });

    }

    // if is above or equal to 768px
    if (responsive_viewport >= 768) {

        // load gravatars
        $('.comment img[data-gravatar]').each(function(){
            $(this).attr('src',$(this).attr('data-gravatar'));
        });

    }

    // off the bat large screen actions
    if (responsive_viewport > 1030) {

    }

});

/*
    ACF GMAP
    ____________________

    Dette script tester om der er et ACF Gmap div,
    og indsætter herefter en API KEY samt en callback function
    som kører gmap indstillingerne i gmap.js filen.
*/
jQuery(document).ready(function($) {

    var element = document.getElementsByClassName('acf-map');
    var gmapAPI = 'AIzaSyA8aATdalTIWL48ycQwPd9s_-k4YqMtTdg';
    var callbackFunction = 'gmapReady';

    if(element.length > 0) {
        $('footer').prepend('<script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key='+gmapAPI+'&callback='+callbackFunction+'"></script>');
    }

});

// Navigation scroll function
function checkScroll(){
    var navBar = jQuery(".navbar");
    var topNavHeight = navBar.height();
    var scroll = jQuery(window).scrollTop();

    if (scroll >= topNavHeight) {
        navBar.addClass('scrolled');
    } else {
        navBar.removeClass('scrolled');
    }
}
