/**
 * All video related scripts.
 */
jQuery(document).ready(function($) {

    var $header_video = $('header .vid video');
    var header_video = document.querySelector('header .vid video');
    var responsive_viewport = $(window).width();

    if (responsive_viewport >= 768) {
      playPause(header_video);
    } else {
      $header_video.hide();
    }

});

function playPause(video) {

	if (video.paused) {
		video.play();
	}

}
