jQuery(document).ready(function($) {

    var grid_breakpoints = {
        sm: 480,
        md: 768,
        lg: 992,
        xl: 1200
    };

    $frontpage = $('.list_slider');
    $frontpageList = $('body.home ul.list-group a.list-group-item');

    $frontpage.slick({
        dots: false,
        arrows: false,
        draggable: true,
        responsive:  [
            {
                breakpoint: grid_breakpoints.lg,
                settings: {
                    dots: true,
                }
            }
        ]
    });

    $frontpage.on('beforeChange', function(event, slick, currentSlide){
        // console.log('before',currentSlide);
        $($frontpageList[currentSlide]).removeClass('active');
    });
    $frontpage.on('afterChange', function(event, slick, currentSlide){
        $($frontpageList[currentSlide]).addClass('active');
        // console.log('after',currentSlide);
    });

    $frontpageList.click(function(e){
        slideIndex = jQuery(this).index();
        if($frontpage) {
            $frontpage.slick( 'slickGoTo', slideIndex );
        }
    });

});
